define("discourse/plugins/discourse-subscriptions/discourse/components/create-coupon-form", ["exports", "@ember/component", "discourse-common/utils/decorators", "@ember/template-factory"], function (_exports, _component, _decorators, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="create-coupon-form">
    <form class="form-horizontal">
      <p>
        <label for="promo_code">
          {{i18n "discourse_subscriptions.admin.coupons.promo_code"}}
        </label>
        <Input @type="text" name="promo_code" @value={{this.promoCode}} />
      </p>
  
      <p>
        <label for="amount">
          {{i18n "discourse_subscriptions.admin.coupons.discount"}}
        </label>
        <ComboBox
          @content={{this.discountTypes}}
          @value={{this.discountType}}
          @onChange={{action (mut this.discountType)}}
        />
        <Input
          class="discount-amount"
          @type="text"
          name="amount"
          @value={{this.discount}}
        />
      </p>
  
      <p>
        <label for="active">
          {{i18n "discourse_subscriptions.admin.coupons.active"}}
        </label>
        <Input @type="checkbox" name="active" @checked={{this.active}} />
      </p>
    </form>
  
    <DButton
      @action={{action "createNewCoupon"}}
      @label="discourse_subscriptions.admin.coupons.create"
      @title="discourse_subscriptions.admin.coupons.create"
      @icon="plus"
      class="btn-primary btn btn-icon"
    />
  
    <DButton
      @action={{action "cancelCreate"}}
      label="cancel"
      @title="cancel"
      @icon="times"
      class="btn btn-icon"
    />
  </div>
  */
  {
    "id": "JHDvYbhK",
    "block": "[[[10,0],[14,0,\"create-coupon-form\"],[12],[1,\"\\n  \"],[10,\"form\"],[14,0,\"form-horizontal\"],[12],[1,\"\\n    \"],[10,2],[12],[1,\"\\n      \"],[10,\"label\"],[14,\"for\",\"promo_code\"],[12],[1,\"\\n        \"],[1,[28,[35,0],[\"discourse_subscriptions.admin.coupons.promo_code\"],null]],[1,\"\\n      \"],[13],[1,\"\\n      \"],[8,[39,1],[[24,3,\"promo_code\"]],[[\"@type\",\"@value\"],[\"text\",[30,0,[\"promoCode\"]]]],null],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[10,2],[12],[1,\"\\n      \"],[10,\"label\"],[14,\"for\",\"amount\"],[12],[1,\"\\n        \"],[1,[28,[35,0],[\"discourse_subscriptions.admin.coupons.discount\"],null]],[1,\"\\n      \"],[13],[1,\"\\n      \"],[8,[39,2],null,[[\"@content\",\"@value\",\"@onChange\"],[[30,0,[\"discountTypes\"]],[30,0,[\"discountType\"]],[28,[37,3],[[30,0],[28,[37,4],[[30,0,[\"discountType\"]]],null]],null]]],null],[1,\"\\n      \"],[8,[39,1],[[24,0,\"discount-amount\"],[24,3,\"amount\"]],[[\"@type\",\"@value\"],[\"text\",[30,0,[\"discount\"]]]],null],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[10,2],[12],[1,\"\\n      \"],[10,\"label\"],[14,\"for\",\"active\"],[12],[1,\"\\n        \"],[1,[28,[35,0],[\"discourse_subscriptions.admin.coupons.active\"],null]],[1,\"\\n      \"],[13],[1,\"\\n      \"],[8,[39,1],[[24,3,\"active\"]],[[\"@type\",\"@checked\"],[\"checkbox\",[30,0,[\"active\"]]]],null],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[8,[39,5],[[24,0,\"btn-primary btn btn-icon\"]],[[\"@action\",\"@label\",\"@title\",\"@icon\"],[[28,[37,3],[[30,0],\"createNewCoupon\"],null],\"discourse_subscriptions.admin.coupons.create\",\"discourse_subscriptions.admin.coupons.create\",\"plus\"]],null],[1,\"\\n\\n  \"],[8,[39,5],[[24,\"label\",\"cancel\"],[24,0,\"btn btn-icon\"]],[[\"@action\",\"@title\",\"@icon\"],[[28,[37,3],[[30,0],\"cancelCreate\"],null],\"cancel\",\"times\"]],null],[1,\"\\n\"],[13]],[],false,[\"i18n\",\"input\",\"combo-box\",\"action\",\"mut\",\"d-button\"]]",
    "moduleName": "discourse/plugins/discourse-subscriptions/discourse/components/create-coupon-form.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, _component.default.extend(dt7948.p({
    discountTypes() {
      return [{
        id: "amount",
        name: "Amount"
      }, {
        id: "percent",
        name: "Percent"
      }];
    },
    discountType: "amount",
    discount: null,
    promoCode: null,
    active: false,
    actions: {
      createNewCoupon() {
        const createParams = {
          promo: this.promoCode,
          discount_type: this.discountType,
          discount: this.discount,
          active: this.active
        };
        this.create(createParams);
      },
      cancelCreate() {
        this.cancel();
      }
    }
  }, [["method", "discountTypes", [_decorators.default]]])));
});