define("discourse/plugins/discourse-subscriptions/discourse/components/payment-options", ["exports", "@ember/component", "discourse-common/utils/decorators", "@ember/template-factory"], function (_exports, _component, _decorators, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <p>
    {{i18n "discourse_subscriptions.plans.select"}}
  </p>
  
  <div class="subscribe-buttons">
    {{#each this.orderedPlans as |plan|}}
      <PaymentPlan
        @plan={{plan}}
        @selectedPlan={{this.selectedPlan}}
        @clickPlan={{action "clickPlan"}}
      />
    {{/each}}
  </div>
  */
  {
    "id": "91QqUDCB",
    "block": "[[[10,2],[12],[1,\"\\n  \"],[1,[28,[35,0],[\"discourse_subscriptions.plans.select\"],null]],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,0],[14,0,\"subscribe-buttons\"],[12],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,0,[\"orderedPlans\"]]],null]],null],null,[[[1,\"    \"],[8,[39,3],null,[[\"@plan\",\"@selectedPlan\",\"@clickPlan\"],[[30,1],[30,0,[\"selectedPlan\"]],[28,[37,4],[[30,0],\"clickPlan\"],null]]],null],[1,\"\\n\"]],[1]],null],[13]],[\"plan\"],false,[\"i18n\",\"each\",\"-track-array\",\"payment-plan\",\"action\"]]",
    "moduleName": "discourse/plugins/discourse-subscriptions/discourse/components/payment-options.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, _component.default.extend(dt7948.p({
    orderedPlans(plans) {
      if (plans) {
        return plans.sort((a, b) => a.unit_amount > b.unit_amount ? 1 : -1);
      }
    },
    didInsertElement() {
      this._super(...arguments);
      if (this.plans && this.plans.length === 1) {
        this.set("selectedPlan", this.plans[0].id);
      }
    },
    actions: {
      clickPlan(plan) {
        this.set("selectedPlan", plan.id);
      }
    }
  }, [["method", "orderedPlans", [(0, _decorators.default)("plans")]]])));
});