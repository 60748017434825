define("discourse/plugins/discourse-subscriptions/discourse/routes/user-billing-index", ["exports", "@ember/routing/route", "@ember/service"], function (_exports, _route, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _route.default.extend({
    router: (0, _service.service)(),
    templateName: "user/billing/index",
    redirect() {
      this.router.transitionTo("user.billing.subscriptions.index");
    }
  });
});