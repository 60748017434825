define("discourse/plugins/discourse-subscriptions/discourse/routes/admin-plugins-discourse-subscriptions", ["exports", "@ember/object", "@ember/routing/route", "@ember/service"], function (_exports, _object, _route, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _route.default.extend(dt7948.p({
    router: (0, _service.service)(),
    showSettings() {
      const controller = this.controllerFor("adminSiteSettings");
      this.router.transitionTo("adminSiteSettingsCategory", "plugins").then(() => {
        controller.set("filter", "plugin:discourse-subscriptions campaign");
        controller.set("_skipBounce", true);
        controller.filterContentNow("plugins");
      });
    }
  }, [["method", "showSettings", [_object.action]]]));
});